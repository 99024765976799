import React from 'react';

const MainButtonBlue = ({text, black, cta, darkBg}) => {
    return (
        <button
            onClick={cta ? cta() : void(0)}
            className={`main_button blue ${black ? 'black' : ''} ${darkBg ? 'dark_bg' : ''}`}
        >
            {text}
            <svg className='inner_arrow' xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18"
                 fill="none">
                <path d="M10.2112 1L17.2112 8L10.2112 15" stroke="#fff" strokeWidth="2"/>
                <path d="M17.5 8.00098H0" stroke="#fff" strokeWidth="2"/>
            </svg>
        </button>
    );
};

export default MainButtonBlue;